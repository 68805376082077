<template>
  <div class="about">
   <div class="bgimage">
        <el-image fit="cover" :src="datas[arr[number].alis].banner"></el-image>
        <div class="header"></div>
        <div class="bg0">
          <div class="mohu"></div>
          <div class="zhuti bumohu" >
            <div class="flex">
              <div class="tablist" @click="tab(li,index)" :class="{choose:number==index}"  v-for="(li,index) in arr" :key="index">{{li.name}}</div>
            </div>
          </div>
        </div>
      </div>
    <div class="zhuti" v-if="arr[number].name=='诉求直达'">
      <div class="titles">流程指南</div>
      <div class=" suqiu">
        <div class="liucheng"></div>
      </div>
      <div class="shangbao">
        <el-tabs v-model="activeName1" :stretch="true">
          <el-tab-pane :label="li.name" v-for="(li,index) in type1" :key="index" :name="index+''"></el-tab-pane>
        </el-tabs>
        <div class="sbform" v-if="activeName1==0">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="诉求联系人" prop="contacts">
              <el-input  style="width: 222px" v-model="ruleForm.contacts"></el-input>
            </el-form-item>
            <el-form-item label="诉求联系电话" prop="phone" label-width="100px">
              <el-input  style="width: 222px" v-model="ruleForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="诉求分类" prop="categoryId" label-width="100px" >
              <el-select v-model="ruleForm.categoryId" placeholder="请选择诉求分类">
                <el-option :label="li.title" :value="li.id" v-for="(li,index) in type" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="诉求标题" prop="title" label-width="100px" >
              <el-input  style="width: 222px" v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="诉求内容" prop="detail" label-width="100px" >
              <el-input   style="width: 500px"  type="textarea" v-model="ruleForm.detail"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="margin-top: 20px" type="primary" @click="onSubmit" size="small">提交诉求</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-else>
            <div v-for="(li,index) in items1" class="suqiulist" :key="index">
              <div class="suqiutitle">{{li.title}}</div>
              <div>
                <div>回复:</div>
                <div>{{li.reply?li.reply:'暂无回复'}}</div>
              </div>
            </div>
          <div class="fenye">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :current-page="page.current"
                :page-sizes="[10, 50, 100, 200]"
                :page-size="page.size"
                :total="page.total">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="titles">常见问题</div>
      <div>
        <el-tabs v-model="activeName"  @tab-click="tabclick">
          <el-tab-pane :label="li.title" v-for="(li,index) in appealCategoryList" :key="index" :name="index+''"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="anlilist"  v-for="(li,index) in items" :key="index">
        <div class="icon"><div class="text">{{li.categoryName}}</div></div>
        <div class="anlititle flex">
          <span class="colblue">问：</span>
          <div class="flex-1">{{li.title}}</div>
        </div>
        <div class="anlidaan flex">
          <span class="colblue">答：</span>
          <div class="flex-1" v-html="li.text"></div>
        </div>
      </div>
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>
    <div style="min-height: 350px;line-height: 26px;" v-else >
        <div  v-if="arr[number].name=='党建引领'">
          <div class="ct" style="width: 1200px;margin: 120px auto">
            <div class="title">{{djdatas.topTitleList[0].title}}</div>
            <div class="flex">
              <div class="left1">
                <el-carousel :interval="5000" arrow="always">
                  <el-carousel-item v-for="(item,index) in djdatas.leftBannerList" :key="index">
                    <div  style="width: 680px;height: 344px">  <el-image :src="item.image"></el-image></div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="left2 flex-1">
                <div class="tzgg"><div class="line"></div>通知公告<span class="more" @click="goggxq">查看更多</span></div>
                <div>
                  <div class="news" @click="gogg(li)" style="margin: 10px;border-bottom: 1px dashed;padding-bottom: 10px;cursor: pointer" v-for="(li,index) in djdatas.rightNoticeList" :key="index">
                    {{li.title}}
                  </div>
                </div>
              </div>
            </div>
            <div class="title1 flex"><span class="flex-1"></span>党建引领<span class="flex-1"></span></div>
            <div class="content" v-html="djdatas.intro">
            </div>
            <div>
              <div class="flex">
                <div class="flex-1 introBannerList" @click="tabdj(index)" :class="{'introBannerListbg':gjnumber==index}" v-for="(li,index) in djdatas.introBannerList" :key="index">
                   {{li.name}}
                </div>
              </div>
              <div>
                <div style="margin: 20px 0">{{djdatas.introBannerList[gjnumber].content}}</div>
                <div><el-image :src="djdatas.introBannerList[gjnumber].image"></el-image></div>
                <div style="text-align: center;margin: 20px 0">专题集萃</div>
                <div>
                  <el-carousel :interval="5000" arrow="always" type="card">
                    <el-carousel-item v-for="(item,index) in djdatas.subjectBannerList" :key="index">
                      <div  style="width: 500px;height: 300px">  <el-image :src="item.image"></el-image></div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
            <div class="title1 flex"><span class="flex-1"></span>特色党建<span class="flex-1"></span></div>
            <div style="width: 280px;margin: 20px auto">
              <el-tabs v-model="tsnumber">
                <el-tab-pane :label="li.name" v-for="(li,index) in djdatas.featureList" :key="index" :name="index+''"></el-tab-pane>
              </el-tabs>
            </div>
            <div class="flex">
              <div class="flex-1" style="padding: 55px">{{djdatas.featureList[tsnumber].content}}</div>
              <div style="width: 565px;height: 348px;margin-left: 25px"><el-image :src="djdatas.featureList[tsnumber].image"></el-image></div>
            </div>
          </div>
        </div>
        <div  v-else-if="arr[number].name=='市局概况'">
            <div :style="`background:url(${datas[arr[number].alis].bgimage1}) no-repeat`">
              <div style="width: 1200px;margin: 0 auto;padding: 85px 0;" class="flex" >
                  <div class="flex-1" v-html="datas[arr[number].alis].text1"></div>
                  <div style="width: 598px;height: 387px;margin-left: 25px"><el-image :src="datas[arr[number].alis].image1"></el-image></div>
              </div>
            </div>
          <div :style="`background:url(${datas[arr[number].alis].bgimage2}) no-repeat`">
            <div style="width: 1200px;margin: 0 auto;padding: 85px 0;" class="flex" >
              <div style="width: 598px;height: 387px;margin-left: 25px"><el-image :src="datas[arr[number].alis].image2"></el-image></div>
              <div class="flex-1" v-html="datas[arr[number].alis].text2"></div>
            </div>
          </div>
          <div :style="`background:url(${datas[arr[number].alis].bgimage3}) no-repeat`" >
            <div style="width: 1200px;margin: 0 auto;padding: 85px 0;" class="flex" >
              <div class="flex-1" v-html="datas[arr[number].alis].text3"></div>
              <div style="width: 598px;height: 387px;margin-left: 25px"><el-image :src="datas[arr[number].alis].image3"></el-image></div>
            </div>
          </div>
        </div>
        <div v-else>
          <div :style="`height:1000px;background:url(${datas[arr[number].alis].bgimage}) no-repeat;background-size:cover`">
            <div style="width: 1200px;margin: 0 auto;padding-top: 200px"  v-html="datas[arr[number].alis].text"></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import config from "../utils/config";
import {appealindex, directappealconsult, faqlist, myconsult} from "../request/moudle";

export default {
  name: "",
  data(){
    return {
      datas:'',
      djdatas:'',
      type:[],
      imgSrc: '',
      userInfo:  this.common.getSessionStorage('userInfo'),
      activeName:'0',
      activeName1: '0',
      page:{
        current:1,
        size:10,
        total:0
      },
      page1:{
        current:1,
        size:10,
        total:0
      },
      appealCategoryList:[],
      items:[],
      items1:[],
      type1:[{name:'诉求提交'},{name:'我的诉求'}],
      test1:require('../assets/banner/test1.png'),
      number:0,
      gjnumber:0,
      tsnumber:0,
      arr:[{name:'市局概况',alis:'sjgk'},{name:'平台介绍',alis:'ptjs'},{name:'诉求直达',alis:'sqzd'}],
      ruleForm:{},
      rules: {
        contacts: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        categoryId: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        detail:[{ required: true, message: '请输入', trigger: 'blur' },]
      },
    }
  },
  created() {
    this.loadconfig()
  },
  mounted() {
  },
  methods:{
    gogg(li){
      this.$router.push({
        path:'/gonggaoxq',
        query:{
          id:li.id
        }
      })
    },
    goggxq(){
      this.$router.push({
        path:'/gonggaolist',
        query:{
          type:this.djdatas.rightNoticeType
        }
      })
    },
    tabdj(index){
      this.gjnumber=index
    },
    loadconfig(){
      this.$api.index.aboutindex().then((res)=>{
         this.datas=res.data;
      })
    },
    loadconfig1(){
      this.$api.index.appealindex().then((res)=>{
        this.type=JSON.parse(JSON.stringify(res.data.appealCategoryList))
        this.appealCategoryList=res.data.appealCategoryList;
        this.appealCategoryList.unshift({title:'全部',id:''})
        this.loadlist()
        this.loadlist1()
      })
    },
    loaddj(){
      this.$api.index.homeData().then((res)=>{
        this.djdatas=res.data;
      })
    },
    tab(li,index){
      this.number = index
      if(this.arr[this.number].alis=='djyl'){
        this.loaddj()
      }
      if(this.arr[this.number].alis=='sqzd'){
        this.loadconfig1()
      }
    },
    tabclick(){
      this.loadlist(true)
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        categoryId: this.appealCategoryList[this.activeName].id
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.index.faqlist(obj).then((res)=>{
        this.items=res.data.records
        this.page.total=res.data.total
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    loadlist1(bol){
      if(bol){
        this.page1.current=1
      }
      const obj={

      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.index.myconsult(obj).then((res)=>{
        this.items1=res.data.records
        this.page1.total=res.data.total
      })
    },
    handleSizeChange1(val){
      this.page1.size=val
      this.loadlist1()
    },
    handleCurrentChange1(val) {
      this.page1.current=val
      this.loadlist1()
    },
    onSubmit(){
      if(!this.userInfo){
        this.$message.error('请先登录!');
        this.login()
      }else{
        const obj={
          ...this.ruleForm
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$api.index.directappealconsult(obj).then((res)=>{
              this.$message.success('提交成功!');
            })
          } else {
            return false;
          }
        });

      }
    },
    login(){
      this.$router.push({
        path:'/login'
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.fenye{
  text-align: center;
  padding: 20px;
}
.suqiulist{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
  color:#747474;
  margin-bottom:15px;
  border-radius: 4px;
  .suqiutitle{
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bolder;
    color:#333333;
  }
}
.about{
  background: #fff!important;
  overflow: hidden;
  .titles{
    font-size: 20px;
    padding: 56px 0;
    text-align: center;
  }
  .suqiu{
    .liucheng{
      height: 85px;
      background: url("../assets/about/img.png");
      background-size: 100% 100%;
    }

  }
  .shangbao{
    padding: 32px;
    .sbform{
      padding: 32px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
      /deep/ .el-tabs__active-bar{
        background-color: #fff!important;
      }
    }
  }
  .anlilist{
    padding: 32px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    position: relative;
    margin: 25px 0;
    color:#747474;
    .anlititle{
      min-height: 42px;
    }
    .colblue{
      color: #0078F2;
      display: inline-block;
      width: 120px;
      text-align: right;
      margin-right: 15px;
    }
    .icon{
      width: 155px;
      height: 146px;
      position: absolute;left: 0;
      top: -3px;
      background: url("../assets/about/img_1.png");
      background-size: 100% 100%;
      .text{
        margin-left: 17px;
        margin-top: 16px;
        transform: rotate(-45deg);color: #fff;
      }
    }
  }
  .header{
    height: 70px;
    color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  .bgimage{
    height: 390px;
    position: relative;
  }
  .bg0{
    height: 45px;
    line-height: 45px;
    width: 1200px;
    margin: 0 auto;
    .mohu{
      width: 100%;
      filter: blur(1px);
      position: absolute;
      z-index: 1;
    }
    .bumohu{
      position: absolute;
      z-index: 2;
    }
    .tablist{
      text-align: center;
      cursor: pointer;
      font-weight: bolder;
      margin-right: 35px;
    }
    .choose{
      color: #409EFF;
    }
  }
  .zhuti{
    width: 1200px;
    margin: 0 auto;
  }
}
.ct{
  margin-top: 50px;
  /deep/ .el-tabs__active-bar{
    background-color: red!important;
  }
  /deep/  .el-tabs__item.is-active{
    color: red!important;
  }
  .introBannerList{
    height: 80px;
    line-height: 80px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    background: linear-gradient(0deg,#F70D0D 100%,#BE0B0B 100%);
    cursor: pointer;
    margin-top: 25px;
  }
  .introBannerListbg{
    background:#98000C;
  }
  .title1{
    width: 1200px;
    margin:30px auto;
    position: relative;
    font-size: 20px;
    font-weight:550;
    color:#F90D0D ;
    span{
      border-top: 2px solid #F90D0D;
      margin: 0 15px;
      margin-top: 14px;
    }
  }
  .title{
    font-size: 22px;
    text-align: center;
    padding-bottom: 45px;
  }

  .left1{
    width: 680px;
    height: 340px;
    margin-right: 36px;
  }
  .left2{
    position: relative;
    .line{
      position: absolute;
      left: 0;
      top: 0;
      border-left: 8px solid #F90D0D;
      height: 25px;
    }
    .tzgg{
      border-bottom: 1px solid #F90D0D;
      color: #3B3E45;
      font-size: 18px;
      font-weight: 550;
      padding-bottom: 8px;
      padding-left: 18px;
    }
    .more{
      color: #3B3E45;
      font-size: 14px;
      float: right;
      font-weight: 500;
    }
  }
}
</style>